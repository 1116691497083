import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';

import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PurchaseRequastAdminComponent } from './purchase-requast-admin/purchase-requast-admin.component';

import { SidebarModule } from 'primeng/sidebar';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

import { UserComponent } from './user/user.component';
import { VerifyComponent } from './user/verify/verify.component';

import { LogoutComponent } from './logout/logout.component';

import { SharedModule } from './shared/shared.module';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from './service/authentication.service';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { AuthenticationGuard } from './guard/authentication.guard';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { StockService } from './service/stock.service';
import { UserService } from './service/user.service';
import { AssertModule } from './assert/assert.module';
import { NotificationService } from './service/notification.service';
import {
  NotifierService,
  NotifierModule,
  NotifierOptions,
} from 'angular-notifier';
import { AssertService } from './service/assert.service';
import { ManagementComponent } from './projectmanagement/management/management.component';
import { MailinglistComponent } from './mailing/mailinglist/mailinglist.component';
import { SystemComponent } from './system/system/system.component';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 10000,

    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    RegisterComponent,
    NavbarComponent,
    FooterComponent,
    LogoutComponent,
    PurchaseRequastAdminComponent,
    ProfileComponent,
    ResetpasswordComponent,
    VerifyComponent,
    ManagementComponent,
    MailinglistComponent,
    SystemComponent,

    

  ],
  imports: [
    AppRoutingModule,
    NotifierModule.withConfig(customNotifierOptions),
    SharedModule,
   ToastModule,
    ButtonModule,
    TableModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    DialogModule,
    HttpClientModule,
    ToolbarModule
  ],

  providers: [
    MessageService,
    UserService,
    StockService,
    AssertService,
    NotificationService,
    NotifierService,
    AuthenticationService,
    
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
