import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService, SelectItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/service/authentication.service';

interface Station {
  id: number;
  name: string;
  // Add other properties as needed
}

interface Asset {
  id: number;
  name: string;
  serial: string;
  assetNumber: string;
  location: string;
  assetDisc: string
}

interface Location {
  id: number;
  name: string;
  // Add other properties as needed
}

interface ApiResponse<T> {
  content: T[];
  // Include other properties as needed, like total count
}

@Component({
  selector: 'app-assettransfer',
  templateUrl: './assettransfer.component.html',
  styleUrls: ['./assettransfer.component.css']
})

export class AssettransferComponent implements OnInit {

  private authenticationService: AuthenticationService;
  data: any[] = [];
  loading = false;
  totalRecords: number;
  stations: any[] = [];
  assets: any[] = [];
  locations: Location[];
  selectedStationId: number | null = null;
  selectedAssetId: number | null = null;
  showRequestForm: boolean = false;
  selectedLocationId: number | null = null;
  fromStation: string | undefined;
  moveReason: string | undefined;
  fromAsset: string | undefined;
  toStation: string | undefined;
  toLocation: string | undefined;
  selectionOffices: any[] = [];
  selectionAssets: any[] = [];
  router: any;
  constructor(private http: HttpClient, private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.fetchData(0, 10); // Initial page load
    this.fetchStations();
  }
  getEventValue(event: any) {
    return event.target.value;
  }
  

  
  fetchData(page: number, size: number): void {
    this.loading = true;
    const url = `${environment.apiUrl}/api/v1/station/asset/move/requests/all?page=${page}&size=${size}&stationId=${this.selectedStationId==null?0:this.selectedStationId}`;
    this.http.get<any[]>(url).subscribe(
      (response) => {
        this.data = response;
        this.totalRecords = response.length; // Assuming total records are returned in the response
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    );
  }

  onMoveSubmit() {
    if (!this.fromStation || !this.fromAsset || !this.moveReason || !this.toStation || !this.toLocation) {
      console.log(this.moveReason)
      alert("Please enter all required fields.");
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter all required fields.', life: 3000 });
      return;
    }

    const requestData = {
      assertId: this.fromAsset,
      locationId: this.toLocation,
      reason: this.moveReason
    };

    this.http.post(`${environment.apiUrl}/api/v1/station/asset/move/requests/request`, requestData).subscribe(
      (response) => {
        console.log('Move request created successfully:', response);
        this.fetchData(0, 10); // Refresh the data
        this.showRequestForm = false;
      },
      (error) => {
        console.error('Failed to create move request:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to create move request', life: 3000 });
        this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: error, life: 3000 });

      }
    );
  }

  onFromStationChange() {
    if (this.fromStation) {
      this.http.get<Asset[]>(`${environment.apiUrl}/assert/getByStationMin?stationId=${this.fromStation}`).subscribe(
        (data) => {
          this.selectionAssets = data.map(asset => ({
            label: `${asset.name} - ${asset.serial}`,
            value: asset.id
          }));
          this.fromAsset = undefined; // Reset asset selection when station changes
        },
        (error) => {
          console.error('Error fetching assets:', error);
        }
      );
    } else {
      this.selectionAssets = [];
      this.fromAsset = undefined;
    }
  }

  onToStationChange() {
    if (this.toStation) {
      this.http.get<Location[]>(`${environment.apiUrl}/api/v1/station/location/all?stationId=${this.toStation}`).subscribe(
        (data) => {
          this.selectionOffices = data.map(location => ({
            label: location.name,
            value: location.id
          }));
          this.toLocation = undefined; // Reset location selection when station changes
        },
        (error) => {
          console.error('Error fetching locations:', error);
        }
      );
    } else {
      this.selectionOffices = [];
      this.toLocation = undefined;
    }
  }
  approveRequest(requestId: number): void {
    this.http.post(`${environment.apiUrl}/api/v1/station/asset/move/requests/approve?assertMoveRequestId=${requestId}`, null).subscribe(
      () => {
        console.log('Request approved successfully');
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Request approved successfully', life: 3000 });
        this.fetchData(0, 10);

      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: 'Failed to approve request', life: 3000 });
          this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: error, life: 3000 });
        console.error('Failed to approve request:', error)
      }
    );
  }

  rejectRequest(requestId: number): void {
    this.http.post(`${environment.apiUrl}/api/v1/station/asset/move/requests/reject?assertMoveRequestId=${requestId}`, null).subscribe(
      () => {
        console.log('Request rejected successfully');
        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Request rejected successfully', life: 3000 });
        this.fetchData(0, 10);

      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: 'Failed to reject request', life: 3000 });
          this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail: error, life: 3000 });
        console.error('Failed to reject request:', error);
      }
    );
  }

  fetchStations(): void {
    this.http.get<Station[]>(`${environment.apiUrl}/station/getAll`).subscribe(
      (data) => {
        this.stations = data.map(station => ({
          label: station.name,
          value: station.id
        }));
      },
      (error) => {
        console.error('Error fetching stations:', error);
      }
    );
  }

  onStationSelected(newStationId: number) {
    this.selectedStationId = newStationId;
    if (this.selectedStationId) {
      this.fetchAssetsAndLocations(this.selectedStationId);
    }
  }
  onStationChange(): void {
    // this.selectedStationId = event.target.value;
    if (this.selectedStationId) {
      this.fetchAssetsAndLocations(this.selectedStationId);
    }
    console.log(this.selectedStationId)
    this.fetchData(0,10);
  }

  fetchAssetsAndLocations(stationId: number): void {
    // Fetch assets
    this.http.get<ApiResponse<Asset>>(`${environment.apiUrl}/assert/getByStation?stationId=${stationId}`).subscribe(data => {
      this.assets = data.content;
    });

    // Fetch locations for the selected station
    this.http.get(`${environment.apiUrl}/api/v1/station/location/all?stationId=${stationId}`).subscribe(data => {
      this.locations = data as any;
      console.log(this.locations)

    });
  }

  submitNewRequest(event: Event): void {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log("creating new request");
    if (!this.selectedStationId || !this.selectedAssetId || !this.selectedLocationId) {
      // alert("Please select a station, asset, and location.");
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select a station, asset, and location.', life: 3000 });
      return;
    }

    const requestData = {
      assertId: this.selectedAssetId as number,
      locationId: this.selectedLocationId as number,
    };

    // Implement the logic to send the request data to the server
    console.log(requestData);
    this.http.post(`${environment.apiUrl}/api/v1/station/asset/move/requests/request`, requestData).subscribe(
      (response) => {
        console.log('Request created successfully:', response);
        // Handle success, e.g., show a message or close the form
        this.fetchData(0, 10);
      },
      (error) => {
        console.error('Failed to create request:', error);
        if(error.message){
          this.messageService.add({ severity: 'error', summary: 'Unsuccessful', detail:error, life: 3000 });
          }
        // Handle failure, e.g., show an error message
      }
    );
    this.toggleRequestForm(); // Hide the form after submission
  }
  toggleRequestForm(): void {
    this.showRequestForm = !this.showRequestForm;
  }



  


public onLogOut(): void {
  this.authenticationService.logOut();
  this.router.navigate(['./login']);
  // this.sendNotification(NotificationType.SUCCESS, `You've been successfully logged out`);
}

}
